<template>
  <div>
    <nav-bar headerTitle="编辑意向" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container col-start-center">
      <div class="block-item">
        <div class="row-between-center">
          <h3 class="f30 fw6">确认你的手机号</h3>
          <div></div>
        </div>
        <div class="content-box">
          <van-field
            class="inp"
            v-model="params.contactPhone"
            clear-trigger="always"
            clearable
            placeholder="请输入手机号"
            type="tel"
          />
        </div>
      </div>

      <div class="block-item">
        <div class="row-between-center">
          <h3 class="f30 fw6">上课门店</h3>
          <div @click="openStore" class="btn f22 row-center-center">
            <van-icon name="sort" />
            <span class="fw6">切换门店</span>
          </div>
        </div>
        <div class="content-box">
          <div class="store">
            <div class="row-between-center">
              <p class="name f28 fw6">{{ venueObj.title }}</p>
              <p class="f22">{{ venueObj.distanceStr }}</p>
            </div>
            <p class="place f22">{{ venueObj.address }}</p>
          </div>
        </div>
      </div>

      <div class="block-item" style="padding-bottom: 4px;">
        <div class="row-between-center">
          <h3 class="f30 fw6">意向教练性别（可选填）</h3>
          <div></div>
        </div>
        <p class="tip f22">优先您的需求匹配，若无适合教练会给您匹配其它合适的教练</p>
        <div class="select-box row-between-center flex-wrap f24">
          <div @click="chooseItem(item.code)" v-for="item in defaultObj.intentionSexList" :key="item.code" :class="['select-item', 'row-center-center', params.intentionSex === item.code ? 'active fw6' : '']">{{ item.name }}</div>
        </div>
      </div>

      <div class="block-item" style="padding-bottom: 4px;">
        <div class="row-between-center">
          <h3 class="f30 fw6">运动目的（可多选）</h3>
          <div></div>
        </div>

        <div class="select-box row-between-center flex-wrap f24">
          <div @click="chooseMoreItem(item.code)" v-for="item in defaultObj.intentionSportPurposeList" :key="item.code" :class="['select-item', 'row-center-center', params.intentionSportPurposeList.includes(item.code) ? 'active fw6' : '']">{{ item.name }}</div>
          <div class="select-item" style="opacity: 0; height: 0; margin: 0;"></div>
        </div>
      </div>

      <div class="block-item">
        <div class="row-between-center">
          <h3 class="f30 fw6">备注（可选填）</h3>
          <div></div>
        </div>
        <div class="content-box">
          <van-field
            v-model="params.remarks"
            rows="1"
            autosize
            type="textarea"
            placeholder="可输入你的其它需求"
          />
        </div>
      </div>

    </div>

    <div class="button-box">
        <p class="row-center-center f24"><van-icon name="phone-o" />店长将在30分钟内致电给您</p>
        <div @click="confirm" :class="['button', 'row-center-center', 'fw6', disabled ? 'disabled' : '']">立即预约体验</div>
      </div>

    <city-venue ref="refCityVenue" :cityId="cityId" label="*请选择意向门店" @setVenue="setVenue" />
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import cityVenue from "@/components/cl-city-venue/cityVenue.vue"
import Regular from '@/lib/regular'
import { newAppBack, initBack } from '@/lib/appMethod'
import wx from 'weixin-js-sdk'
import Vconsole from 'vconsole';


export default {
  mixins: [userMixin],
  components: {
    navBar, cityVenue
  },
  data() {
    return {
      params: {
        contactPhone: '',
        intentionSex: '',
        intentionSportPurposeList: [],
        remarks: '',
        token: '',
        userId: '',
        venueId: ''
      },
      defaultObj: {

      },

      venueObj: {},
      disabled: true
    }
  },
  watch: {
    'params.contactPhone'(v) {
      this.disabled = Regular.isTelenum(v) ? false : true

    }
  },
  async created() {
    // 初始化
    await this.$getAllInfo(['userId', 'cityId', 'venueId', 'local'])
    initBack()
    if (this.userId == '9634239') {
      new Vconsole()
    }

    Object.assign(this.params, {userId: this.userId, token: this.token, venueId: this.venueId || '1' })
console.log('local', this.local);

    await this.getVenueInfo()

    this.getDetail()
  },

  methods: {
    newAppBack,

    getDetail() {

      this.$axios.post(`${this.baseURLApp}/per/app/user/preferred/recommend/applyConfig`, {
        userId: this.userId,
        token: this.token
      }).then(res => {
        const r = res.data;
        this.defaultObj = r
        this.params.contactPhone = r.mobilePhone
      }).catch(r => {

      })
    },
    getVenueInfo() {
      return this.$axios.post(this.baseURLApp + '/indoorhome/venue/details', {
        venueId: this.params.venueId,
        lat: this.local ? this.local.lat : '',
        lng: this.local ? this.local.lng : '',
      }).then((r) => {
        this.venueObj = r.data
      })
    },
    openStore() {
      this.$refs.refCityVenue.popShow = true;
    },
    setVenue(item) {
      this.params.venueId = item.id
      this.getVenueInfo()
    },


    chooseItem(code) {
      this.params.intentionSex = code === this.params.intentionSex ? '' : code
    },
    chooseMoreItem(code) {
      let list = this.params.intentionSportPurposeList
      const i = list.indexOf(code)
      list.includes(code) ? list.splice(i, 1) : list.push(code)
    },
    confirm() {
      if (!Regular.isTelenum(this.params.contactPhone)) {
        this.$toast('请输入正确的手机号')
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: '加载中...',
      })
      this.$axios.post(`${this.baseURLApp}/per/app/user/preferred/recommend/apply`, this.params).then(res => {
        this.$toast('预约成功')

        setTimeout(() => {
          this.newAppBack()
        }, 2000)
      }).catch(() => {

      })
    },
  },

}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}

.container {
  background-color: #f5f5f5;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  width: 100%;
  padding: 0 24px calc(24px + env(safe-area-inset-bottom));
  .block-item {
    width: 100%;
    margin: 24px 0 0;
    background-color: #fff;
    padding: 32px 28px;
    border-radius: 8px;
    .btn {
      .van-icon {
        transform-origin: center;
        transform: rotateZ(90deg);
        font-size: 24px;
        margin: 0 8px 0 0;
      }
    }
    .tip {
      margin: 12px 0 0;
      color: #3C454E;
    }
    .content-box {
      padding: 16px 24px;
      margin: 24px 0 0;
      background: #F7F7F7;
      border-radius: 8px 8px 8px 8px;
      .store {
        .place {
          margin: 16px 0 0;
        }
      }
      .van-cell {
        padding: 0;
        background-color: inherit;
        height: 100%;
      }
    }
    .select-box {
      padding: 24px 0 0;
      margin: 16px 0 0;
      .select-item {
        width: 200px;
        height: 68px;
        margin: 0 0 24px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #ddd;
        &.active {
          border: 2px solid #242831;
        }
      }
    }
  }





}
.button-box {
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    color: #242831;
    position: sticky;
    bottom: 0;
    padding: 24px 24px calc(12px + env(safe-area-inset-bottom));
    border-top: 1px solid #EBEBEB;
    .button {
      width: 100%;
      height: 96px;
      background: linear-gradient(90deg, #FF8A00 0%, #FF3D00 98%);
      border-radius: 8px;
      margin: 12px 0 0;
      color: #fff;
      &.disabled {
        opacity: .6;
      }
    }
    .van-icon {
      margin: 6px 6px 0 0;
    }
  }
</style>
