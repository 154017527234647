<template>
  <div class="container col-start-center">
    <nav-bar header-title="店长帮你选" :show-back="true" :show-share="true" @right-txt-click="miniShare"
      :header-back="newAppBack" ref="head" />
    <img src="https://img.chaolu.com.cn/ACT/per-nice/banner2.png" alt="" class="banner">

    <div class="main">
      <div :class="['status-box', detail.userLastApply && detail.userLastApply.processStatus === 3 ? 'success' : '']">
        <div class="row-between-center">
          <img
            :src="`https://img.chaolu.com.cn/ACT/per-nice/ttm-${detail.userLastApply && detail.userLastApply.processStatus === 3 ? '2' : '1'}.png`"
            alt="" class="t1">
          <div></div>
          <div></div>
          <div @click="go('per-nice/history')" class="t2 f20 fw6 row-center-center">历史记录<van-icon size="11"
              name="arrow" /></div>
        </div>
        <template v-if="hasLogin">
          <div v-if="detail.userLastApply"
            :class="['content', 'col-center-center', detail.userLastApply.processStatus === 3 ? 'success' : '']">
            <div class="card">
              <div class="row-between-center">
                <div class="f22">
                  <p class="">{{ detail.userLastApply.venueTitle }}</p>
                  <p class="time">{{ detail.userLastApply.processDate }}</p>
                </div>
                <div class="card-status f22 fw6 row-center-center"
                  :class="[detail.userLastApply.processStatus === 3 ? 'success' : detail.userLastApply.processStatus === 4 ? 'fail' : '']">
                  {{ detail.userLastApply.processTitle }}
                </div>
              </div>

              <div v-if="detail.userLastApply.processStatus === 3" class="card-info">
                <div class="row-between-center">
                  <img @click="goCoach" :src="detail.userLastApply.venueRecommendProcessInfo.teacher.teacherHalfImg"
                    alt="" class="coach">
                  <img @click="goCourse" :src="detail.userLastApply.venueRecommendProcessInfo.project.projectMainImg"
                    alt="" class="course">
                </div>
                <div class="row-between-center">
                  <p class="f28 fw6 ellipsis">{{ detail.userLastApply.venueRecommendProcessInfo.teacher.teacherName }}</p>
                  <p class="f24 fw6 ellipsis">(推荐课程：{{ detail.userLastApply.venueRecommendProcessInfo.project.projectName
                  }})</p>
                </div>
              </div>

              <h3 v-else class="card-text f26 fw6">{{ detail.userLastApply.baseProcessInfo &&
                detail.userLastApply.baseProcessInfo.userShowContext }}</h3>
            </div>
          </div>
          <div v-else class="content fw6 col-center-center">
            <div class="card  none">
              已经有<span class="red">{{ recommendedNum }}</span>名用户被成功配对了教练<br />您也开始匹配吧~
              <div @click="go('per-nice/edit', 'location=1')" class="red-button small f30 row-center-center">
                立即解锁专属教练<van-icon name="https://img.chaolu.com.cn/ACT/per-nice/hand.png" /></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="content fw6 col-center-center">
            <div class="card  none">
              <p>请登录后进行教练匹配~</p>
            </div>
          </div>
        </template>
        <div v-if="detail.userLastApply && detail.userLastApply.processStatus === 3" @click="goPerAppoint"
          class="button f28 fw6 row-center-center">立即预约教练</div>
      </div>

      <img v-for="num in 5" :key="num" v-if="num != 2" :src="`https://img.chaolu.com.cn/ACT/per-nice/jjs-${num}.png`"
        alt="" class="progress-box">
    </div>

    <div class="button-box" v-show="bottomButtonShow">
      <div @click="go('per-nice/edit', 'location=1')" class="red-button fw6 row-center-center">立即解锁专属教练<van-icon
          name="https://img.chaolu.com.cn/ACT/per-nice/hand.png" /></div>
    </div>

  </div>
</template>
<script>
import { newAppBack, initBack, goPriDetail, gotoCoachDetail, webAppShare } from '@/lib/appMethod'
import navBar from '@/components/nav-bar-202210/index'
import userMixin from '@/mixin/userMixin'
import wx from 'weixin-js-sdk'

export default {
  components: {
    navBar
  },
  mixins: [userMixin],
  async created() {
    initBack()
    await this.$getAllInfo(['userId', 'cityId'])


    this.getDetail()
    if (this.appTypeStr === 'mini') {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.image,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollFunction)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.getDetail()
      }
    })
  },
  data() {
    return {
      mobilePhone: '',

      venueObj: {},

      detail: {
        userLastApply: {}
      },
      bottomButtonShow: false,
      recommendedNum: 0,
      shareParams: {
        title: '不知道自己适合什么样的私教教练？让店长帮你选吧！',
        image: 'https://img.chaolu.com.cn/ACT/per-nice/mini-share.png',
        path: `/pages/webView/index?webUrl=${window.location.origin}/#/per-nice/index&userId=1`,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      hasLogin:false
    }
  },
  methods: {
    newAppBack,
    getDetail() {
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: '加载中...',
      })
      this.$axios.post(`${this.baseURLApp}/per/app/user/preferred/recommend/index`, {
        userId: this.userId,
        token: this.token,
      }).then(res => {
        const r = res.data;
        this.detail = r
        if (r.userLastApply) {
          window.removeEventListener('scroll', this.scrollFunction)
          this.bottomButtonShow = true
        } else {
          this.bottomButtonShow = false
        }
        this.recommendedNum = r.recommendedNum

        this.mobilePhone = r.mobilePhone
        this.hasLogin = true
        toast.clear()
      }).catch(r => {
        toast.clear()
      })
    },

    goCoach() {
      const teacherId = d.venueRecommendProcessInfo.teacher.teacherId
      gotoCoachDetail(teacherId)
    },
    goCourse() {
      const projectId = d.venueRecommendProcessInfo.project.projectId + ''
      goPriDetail(projectId)
    },
    goPerAppoint() {
      const d = this.detail.userLastApply

      const teacherId = d.venueRecommendProcessInfo.teacher.teacherId
      const teacherName = d.venueRecommendProcessInfo.teacher.teacherName
      const teacherPic = d.venueRecommendProcessInfo.teacher.teacherHalfImg
      const projectId = d.venueRecommendProcessInfo.project.projectId + ''
      const projectName = d.venueRecommendProcessInfo.project.projectName
      const venueId = d.venueId + ''
      const venueName = d.venueTitle

      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/priPages/appointment/index?teacherId=${teacherId}&venueId=${venueId}&venueName=${venueName}&perProjectId=${projectId}`,
        })
      }
      if (this.appTypeStr === 'ios') {
        window.webkit.messageHandlers.CustomViewController.postMessage({
          className: 'SDAppointmentViewController',
          params: {
            teacherId,
            teacherName,
            pic: teacherPic,
            projectId,
            projectName,
            venueId,
            venueName,
          }
        })
      }
      if (this.appTypeStr === 'and') {
        App.web2native(23, JSON.stringify({
          teacherId,
          name: teacherName,
          pic: teacherPic,
          projectId,
          projectName,
          venueId,
          venueName,
        }))
      }
    },
    go(url, query) {
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/${url}&${query}`,
        })
      } else {
        this.$router.push(`/${url}`)
      }
    },
    scrollFunction() {
      const st = (document.body.scrollTop || document.documentElement.scrollTop)
      this.bottomButtonShow = st > 500 ? true : false
    },
    miniShare() {
      console.log('jsdjfkdsj');
      let params = {
        scene: 0,
        ...this.shareParams
      }
      webAppShare('3', params)
    },
  }
}
</script>
<style lang="less" scoped>
  .red{
    color: #FF4000;
    font-size: 52px;
    font-family: BebasNeueBold;
    padding: 0px 10px;
  }
.container {
  background-color: #F5F5F5;

  * {
    box-sizing: border-box;
  }

  .main {
    width: 100%;
    padding: 0 24px 48px;
    margin: -47.3vw 0 0;
  }

  .banner {
    width: 100%;
    object-fit: cover;
    object-position: 0 0;
  }

  .status-box {
    width: 100%;
    background: linear-gradient(207deg, #5CBAFE 0%, #617BFF 100%);
    position: relative;
    padding: 34px 28px;
    position: relative;
    border-radius: 12px;

    &.success {
      background: linear-gradient(13deg, #FFE8C3 0%, #FFDEAC 100%);
    }

    .t1 {
      width: 702px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .t2 {
      width: 128px;
      height: 36px;
      border-radius: 32px 32px 32px 32px;
      background-color: #fff;
      position: relative;
      z-index: 3;
    }


    .content {
      margin: 36px 0 0;

      &.success {
        height: auto;
      }

      .card {
        width: 646px;
        padding: 24px 28px;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
        border-radius: 8px 8px 8px 8px;
        font-size: 28px;
        line-height: 1.5;

        &.none {
          padding: 96px 48px 48px;
          text-align: center;
        }

        .time {
          margin: 8px 0 0;
        }

        .card-status {
          height: 46px;
          background: #5A6585;
          border-radius: 4px 4px 4px 4px;
          padding: 0 14px;
          color: #fff;

          &.fail {
            background: #9AA1A9;
          }

          &.success {
            background: #FF6E00;
          }
        }

        .card-text {
          margin: 26px 0 0;

          width: 100%;
          background: #F7F7F7;
          border-radius: 4px 4px 4px 4px;
          padding: 28px;
        }

        .card-info {
          margin: 24px 0 0;

          img {
            height: 230px;
            object-fit: cover;
            border-radius: 7px;
          }

          p {
            color: #533519;
            margin: 20px 0 0;
          }

          .coach {
            width: 194px;

          }

          .course {
            width: 378px;
          }
        }
      }
    }

    .button {
      width: 646px;
      height: 88px;
      background: #533519;
      border-radius: 8px 8px 8px 8px;
      margin: 30px 0 0;
      color: #FFFAEB;
    }
  }



  .progress-box {
    width: 100%;
    display: block;
  }

  .button-box {
    width: 750px;
    background: #fff;
    position: sticky;
    bottom: 0;
    padding: 24px 24px calc(24px + env(safe-area-inset-bottom));
  }

  .red-button {
    width: 100%;
    height: 96px;
    background: linear-gradient(90deg, #FF8A00 0%, #FF3D00 98%);
    box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    color: #fff;

    &.small {
      margin: 88px 0 0;
    }

    .van-icon {
      margin: 0 0 0 6px;
    }
  }

  .pop-style {
    width: 590px;
    border-radius: 12px;
    padding: 60px 40px 40px;

    .sub-title {
      margin: 12px 0 0;
    }

    .content {
      margin: 42px 0 0;

      .row {
        border-radius: 0px 0px 8px 8px;
        background: #F7F7F7;

        .item {
          width: 510px;
          height: 82px;
          padding: 0 24px;
        }
      }
    }

    .btn-cover {
      width: 100%;
      margin: 48px 0 0;
    }

    .btn {
      width: 244px;
      height: 96px;
      border-radius: 8px;
    }

    .btn.btn-close {
      border: 1px solid #D9D9D9;
    }

    .btn.btn-confirm {
      background: #FFDE00;
    }
  }

}
</style>
