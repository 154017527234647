<template>
  <div>
    <nav-bar headerTitle="历史推荐记录" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container col-start-center">
      <van-list v-model="loading" :finished="finished" @load="getList">
        <div class="card" v-for="item, index in records" :key="index">
          <div class="row-between-center">
            <div class="f22">
              <p class="">{{ item.venueTitle }}</p>
              <p class="time">{{ item.processDate }} 下单</p>
            </div>
            <div class="card-status f22 fw6 row-center-center"
              :class="[item.processStatus === 3 ? 'success' : item.processStatus === 4 ? 'fail' : '']">
              {{ item.processTitle }}
            </div>
          </div>

          <div v-if="item.processStatus === 3" class="card-info">
            <div class="row-between-center">
              <img @click="goCoach(item)" :src="item.venueRecommendProcessInfo.teacher.teacherHalfImg" alt="" class="coach">
              <img @click="goCourse(item)" :src="item.venueRecommendProcessInfo.project.projectMainImg" alt="" class="course">
            </div>
            <div class="row-between-center">
              <p class="f28 fw6 ellipsis">{{ item.venueRecommendProcessInfo.teacher.teacherName }}</p>
              <p class="f24 fw6 ellipsis">(推荐课程：{{ item.venueRecommendProcessInfo.project.projectName }})
              </p>
            </div>
            <div @click="goPerAppoint(item)" class="red-button fw6 row-center-center">立即预约</div>
          </div>

          <h3 v-else class="card-text f26 fw6">{{ item.baseProcessInfo &&
            item.baseProcessInfo.userShowContext }}</h3>

        </div>
      </van-list>

      <div class="empty col-start-center" v-if="records.length === 0">
        <img src="https://img.chaolu.com.cn/ACT/mini-records/empty.png" alt="">
        <p class="f26">暂无历史推荐记录</p>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar-202210/index'
import { newAppBack, initBack, goPriDetail, gotoCoachDetail } from '@/lib/appMethod'
import wx from 'weixin-js-sdk'


export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      records: [], // 训记列表
      pageNum: 1,

      loading: true,
      finished: false,
    }
  },
  computed: {},
  async created() {
    // 初始化
    await this.$getAllInfo();
    initBack()

    this.loading = false
  },

  methods: {
    newAppBack,

    getList() {
      this.$axios.post(this.baseURLApp + '/per/app/user/preferred/recommend/history', {
        current: this.pageNum,
        size: 20,
        userId: this.userId,
        token: this.token,
      }).then((res) => {
        if (!res.data) {
          this.finished = true
          return
        }

        this.records = this.records.concat(res.data)

        if (this.records.length < 20) {
          this.finished = true
        }

        this.pageNum++

        this.loading = false
      })
    },
    goCoach(item) {
      const teacherId = item.venueRecommendProcessInfo.teacher.teacherId
      gotoCoachDetail(teacherId)
    },
    goCourse(item) {
      const projectId = item.venueRecommendProcessInfo.project.projectId + ''
      goPriDetail(projectId)
    },
    goPerAppoint(item) {
      if (item.processStatus !== 3) return

      const teacherId = item.venueRecommendProcessInfo.teacher.teacherId
      const teacherName = item.venueRecommendProcessInfo.teacher.teacherName
      const teacherPic = item.venueRecommendProcessInfo.teacher.teacherHalfImg
      const projectId = item.venueRecommendProcessInfo.project.projectId + ''
      const projectName = item.venueRecommendProcessInfo.project.projectName
      const venueId = item.venueId + ''
      const venueName = item.venueTitle

      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/priPages/appointment/index?teacherId=${teacherId}&venueId=${venueId}&venueName=${venueName}&perProjectId=${projectId}`,
        })
      }
      if (this.appTypeStr === 'ios') {
        window.webkit.messageHandlers.CustomViewController.postMessage({
          className: 'SDAppointmentViewController',
          params: {
            teacherId,
            teacherName,
            pic: teacherPic,
            projectId,
            projectName,
            venueId,
            venueName,
          }
        })
      }
      if (this.appTypeStr === 'and') {
        App.web2native(23, JSON.stringify({
          teacherId,
          name: teacherName,
          pic: teacherPic,
          projectId,
          projectName,
          venueId,
          venueName,
        }))
      }
    },
  },

}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}

.container {
  background-color: #F7F7F7;
  box-sizing: border-box;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }

  width: 100%;
  padding: 0 32px env(safe-area-inset-bottom);
  .van-list {
    width: 100%;
  }
  .card {
    width: 100%;
    padding: 24px 28px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px 8px 8px 8px;
    margin: 28px 0 0;

    .time {
      margin: 8px 0 0;
    }

    .card-status {
      height: 46px;
      background: #5A6585;
      border-radius: 4px 4px 4px 4px;
      padding: 0 14px;
      color: #fff;

      &.fail {
        background: #9AA1A9;
      }

      &.success {
        background: #FF6E00;
      }
    }

    .card-text {
      padding: 24px;
      background-color: #F7F7F7;
      margin: 26px 0 0;
    }

    .card-info {
      margin: 24px 0 0;
      .red-button {
        width: 100%;
        height: 88px;
        background: linear-gradient(90deg, #F9A709 0%, #F67600 100%);
        box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px 8px 8px 8px;
        color: #fff;
        margin: 22px 0 0;
      }
      img {
        height: 244px;
        object-fit: cover;
        border-radius: 7px;
      }

      p {
        color: #533519;
        margin: 20px 0 0;
      }

      .coach {
        width: 207px;

      }

      .course {
        width: 401px;
      }
    }
  }

  .empty {
    margin: 200px auto;
    img {
      width: 320px;
      margin: 0 0 12px;
    }
    p {
      color: #6C727A;
    }
  }
}</style>
