<template>
  <!-- <div class="container">
    <nav-bar header-title="优选私教课" :show-back="true" :header-back="newAppBack" ref="head" />

    <div class="card">
      <div class="card-info" v-if="detail.teacher">
        <div class="row-between-center">
          <img @click="goCoach(detail.teacher.teacherId)" :src="detail.teacher.teacherHalfImg" alt="" class="coach">
          <div class="course-box">
            <img @click="goCourse" :src="detail.project.projectMainImg" alt="" class="course">
            <p class="f28 fw6">{{ detail.project.projectName }}</p>
          </div>

        </div>
        <div class="row-between-center">
          <p class="f28 fw6 ellipsis">{{ detail.teacher.teacherName }}</p>
          <p class="f24 fw6 ellipsis">（推荐课程：{{ detail.project.projectName }}）</p>
        </div>
      </div>

      <div class="course-info" v-if="detail.teacher">
        <div class="a row-start-center">
          <div class="">
            <div class="row-start-center">
              <div class="title f28 fw6 ellipsis">{{ detail.teacher.teacherName }}</div>
              <div class="rank row-center-center f16 fw6">{{ detail.teacher.rateName }}</div>
            </div>
            <div class="labels flex flex_wrap">
              <div class="label row-center-center f22" v-for="label, index in detail.teacher.labelList" :key="index">
                {{ label }}
              </div>
            </div>
          </div>
        </div>
        <div class="b flex_lr_m" v-if="curLesson">
          <div class="c">

            <div :class="['bottom', 'row-start-center', curLesson.voucherAfterPrice !== null ? 'bottom1' : '']">
              <div class="row-start-end">
                <div v-if="curLesson.voucherAfterPrice === null"
                  :class="['price', 'f28', 'flex-none', 'BebasNeueBold', curLesson.voucherAfterPrice !== null ? 'black' : '']">
                  <span class="p1 BebasNeueBold f24">¥</span>
                  <span class="p2 BebasNeueBold f40">{{ curLesson.discountAmount ? curLesson.discountAmount :
                    curLesson.originalPrice }}</span>
                </div>
                <div class="voucher-after-price row-center-center f18 fw6" v-if="curLesson.voucherAfterPrice !== null">
                  券后
                  <span class="BebasNeueBold f20">¥</span>
                  <span class="BebasNeueBold f32">{{ curLesson.voucherAfterPrice }}</span>
                </div>
                <div v-if="curLesson.originalPrice" class="ori-price f20">¥{{ curLesson.originalPrice }}/节</div>
              </div>
              <div v-if="curLesson.discountLabel" class="remark f18 row-center-center">{{ curLesson.discountLabel }}</div>
            </div>
          </div>

        </div>

      </div>
      <div class="store-info row-between-center">
        <div>
          <p class="p1 f26 fw6">{{ detail.venue.venueTitle }}</p>
          <p class="p2 f22">{{ detail.venue.address }}</p>
        </div>
        <div class="col-start-center">
          <img src="https://img.chaolu.com.cn/ACT/per-nice/loc-icon.png" alt="" class="icon">
          <p v-if="detail.venue.distance" class="p3 f20">{{ detail.venue.distance }}</p>
        </div>
      </div>

    </div>

    <div class="card">
      <h2 class="f28 fw6">推荐预约时间</h2>
      <div class="a">
        <div :class="['time-item', 'row-between-center', 'f24', 'fw6', curLesson. item.status === 1 ? '' : 'disabled']">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import navBar from '@/components/nav-bar-202210/index'
import userMixin from '@/mixin/userMixin'
import Vconsole from 'vconsole';
import wx from 'weixin-js-sdk'

export default {
  components: {
    navBar
  },
  mixins: [userMixin],
  async created() {
    initBack()
    await this.$getAllInfo(['userId', 'local'])
    if (this.userId == '9634239') {
      new Vconsole()
    }
    console.log('heylocal', this.local)

    this.params = {
      lat: '',
      lng: '',
      userId: this.userId,
      token: this.token,
      preferredRecommendId: getParam().id
    }

    this.getDetail()
  },
  data() {
    return {
      params: {},
      detail: {},
      curLesson: {},
    }
  },
  methods: {
    newAppBack,
    getDetail() {
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: '加载中...',
      })
      this.$axios.post(`${this.baseURLApp}/per/app/user/preferred/recommend/detail`, this.params).then(res => {
        const r = res.data;
        this.detail = r

        // 筛选可预约的课 2023。07.20注释  支付控制做完可能会重新用
        // const canArr = r.arraList.filter((item) => item.status === 1)
        // console.log(canArr);
        // if (canArr.length) {
        //   this.curLesson = canArr[0]
        // } else {
        //   this.curLesson = undefined
        // }

        // 2023。07.20新增 用作消息通知跳转中转页
        this.goPerAppoint(this.detail)

        toast.clear()
      }).catch(r => {
        toast.clear()
      })
    },
    goCoach() {

    },
    goCourse() {

    },
    goPerAppoint(item) {

      const teacherId = item.teacher.teacherId
      const teacherName = item.teacher.teacherName
      const teacherPic = item.teacher.teacherHalfImg
      const projectId = item.project.projectId + ''
      const projectName = item.project.projectName
      const venueId = item.venue.venueId + ''
      const venueName = item.venue.venueTitle

      if (this.appTypeStr === 'mini') {
        console.log('enter-mini');
        wx.miniProgram.navigateTo({
          url: `/pages/priPages/appointment/index?teacherId=${teacherId}&venueId=${venueId}&venueName=${venueName}&perProjectId=${projectId}`,
        })
      }
      if (this.appTypeStr === 'ios') {
        window.webkit.messageHandlers.CustomViewController.postMessage({
          className: 'SDAppointmentViewController',
          params: {
            teacherId,
            teacherName,
            pic: teacherPic,
            projectId,
            projectName,
            venueId,
            venueName,
          }
        })
      }
      if (this.appTypeStr === 'and') {
        App.web2native(23, JSON.stringify({
          teacherId,
          name: teacherName,
          pic: teacherPic,
          projectId,
          projectName,
          venueId,
          venueName,
        }))
      }
    },
  }
}
</script>
<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }

  .card {
    padding: 20px 32px;
    border-bottom: 16px solid #F7F7F7;

    .card-info {
      margin: 24px 0 0;

      img {
        height: 266px;
        object-fit: cover;
        border-radius: 7px;
      }

      .course-box {
        position: relative;

        p {
          position: absolute;
          left: 28px;
          bottom: 28px;
          color: #fff;
        }
      }

      .coach {
        width: 226px;
      }

      .course {
        width: 438px;
      }
    }

    .course-info {
      width: 100%;
      padding: 32px 0;
      border-bottom: 1px solid #EEEEEE;

      .b {
        margin: 22px 0 0;
      }

      .photo {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 24px 0 0;
      }

      .content {
        padding: 24px;
        height: 298px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      .title {
        color: #242831;
        line-height: 40px;
      }

      .labels {
        height: 32px;
        overflow: hidden;
        margin: 16px 0 0;
      }

      .label {
        height: 32px;
        background: #f5f5f5;
        border-radius: 4px;
        color: #3C454E;
        padding: 0 8px;
        margin: 0 8px 0 0;
        white-space: nowrap;
        flex-grow: 0;
      }

      .rank {
        height: 24px;
        background: #242831;
        color: #fff;
        border-radius: 2px;
        padding: 0 6px;
        margin: 0 0 0 16px;
      }

      .bottom {
        margin: 20px 0 0;
        align-items: flex-end;
      }

      .bottom1 .p2 {
        font-size: 28px;
      }



      .remark {
        padding: 8px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #267DFF;
        color: #267DFF;
        margin: 0 0 0 16px;
      }

      .price {
        font-weight: 600;
        color: #F03C18;
        transform: translateY(4px);
      }

      .price.black {
        color: #242831;
      }

      .price.black .p2 {
        font-size: 28px;
      }

      .price.black .p1 {
        font-size: 20px;
      }

      .price .p3 {
        margin: 0 0 0 4px;
      }

      .voucher-after-price {
        height: 36px;
        /*margin-left: 16px;*/
        padding: 0 8px;
        color: #ffffff;
        background: #F03C18;
        border-radius: 4px;
      }

      .btn {
        padding: 0 24px;
        height: 60px;
        background: #FFDE00;
        border-radius: 8px;
      }


      .ori-price {
        text-decoration: line-through;
        color: #3C454E;
        margin-left: 8px;
      }
    }
    .store-info {
      padding: 32px 0 12px;
      .p2 {
        margin: 12px 0 0;
        color: #6C727A;
      }
      .p3 {
        color: #6C727A;
      }
      .icon {
        width: 56px;
      }
    }


  }
}</style>
